<template>
    <div style="padding-bottom: 10vh;">
        <div class="page-route-select">
            <router-link to="/cliente/monitores/cotacoes" class="page-route-select-text">
                Home
            </router-link>
            <span class="material-icons page-route-select-icon">play_arrow</span>
            <div class="page-route-select-text">
                Logs do Sistema
            </div>
        </div>
        <div class="page-title">Logs do Sistema</div>
        <div style="display: flex; gap: 2vw; margin-top: 2vh; align-items: center;">
            <p style="margin: 0;" class="tab-selector-text">Filtrar por:</p>
            <button class="page-section-select-size" @click="tab = 'cli'" :class="isActiveContainer('cli')">
                <span class="material-icons-outlined desactive-icon" :class="isActiveIcon('cli')">
                    email
                </span>
                <div class="page-section-select-text">
                    Clientes
                </div>
            </button>
            <button class="page-section-select-size" @click="tab = 'use'" :class="isActiveContainer('use')">
                <span class="material-icons-outlined desactive-icon" :class="isActiveIcon('use')">
                    email
                </span>
                <div class="page-section-select-text">
                    Usuários
                </div>
            </button>
            <button class="page-section-select-size" @click="tab = 'sto'" :class="isActiveContainer('sto')">
                <span class="material-icons-outlined desactive-icon" :class="isActiveIcon('sto')">
                    email
                </span>
                <div class="page-section-select-text">
                    Lojas
                </div>
            </button>
        </div>
        <div style="display: flex; gap: 2vw; margin-top: 3vh;">
            <StandardInputListFilteredAuto :selectWidth="'24.6vw'"
                                     class="products-page__inputs-input"
                                     title="Cliente" 
                                     propPlaceholder="test" 
                                     :action="setCompany"
                                     :list="clientsList"
                                     :actionTwo="changeSelect"
                                     :type="'select'"/>
            <StandardInput v-if="tab == 'sto'" :placeholder="'Digite CNPJ'" :title="'CNPJ'" :action="() => { }"
                :type="'text'" />
            <StandardInput v-if="tab == 'sto'" :placeholder="'Digite a razão'" :title="'Razão social'"
                :action="() => { }" :type="'text'" />
            <StandardInput v-if="tab == 'sto' || tab == 'cli'" :placeholder="'Digite o nome'" :title="'Nome fantasia'"
                :action="() => { }" :type="'text'" />
            <StandardInput v-if="tab == 'cli'" :placeholder="'Digite o telefone'" :title="'Telefone'"
                :action="() => { }" :type="'text'" />
            <StandardInput v-if="tab == 'use'" :placeholder="'Digite o login'" :title="'Login'" :action="() => { }"
                :type="'text'" />
            <StandardInput v-if="tab == 'use'" :placeholder="'Digite o email'" :title="'Email'" :action="() => { }"
                :type="'text'" />
        </div>
        <table style="width: 95%; margin-top: 3vh;">
            <tr class="log-table-header">
                <th style="padding-left: 1vw;" class="base-width">Id</th>
                <th class="base-width">Data</th>
                <th class="base-width">Tipo</th>
                <th class="base-width">Modelo</th>
                <th class="base-width">Usuário</th>
            </tr>
            <tr class="log-table-row" v-for="(log,idx) in logs" :key="log.id" :class="{'page-table-line-special': idx % 2 != 0 }">
                <td>{{ log.id }}</td>
                <td>{{ log.created_at }}</td>
                <td>{{ log.action }}</td>
                <td>{{ log.model }}</td>
                <td>{{ '--' }}</td>
            </tr>
        </table>
        <cc-pagination
            classes="orange justify-end" style="margin-top: 2vh;"
            :items_by_page_value="20"
            :total="total_items"
            :page="1"
            v-on:change="updatePage($event)"
        ></cc-pagination>

        <cc-loader-full v-if="isLoading"/>
    </div>
</template>

<script>
import DashboardService from '../../services/v1/user.service';
import StandardInput from '@/components/ui/inputs/StandardInput.vue'
import Paginacao from "@/components/cliente/base-components/Pagination";
import StandardInputListFilteredAuto from '../../components/ui/inputs/StandardInputListFilteredAuto.vue';
import * as CONSTANTS from "@/constants/constants";

export default {
    name: 'LogsPage',
    data() {
        return {
            logs: [],
            svc: new DashboardService,
            tab: 'cli',
            total_items: 0,
            clientsList: [],
            clientName: '',
            isLoading: true,
            clientId: undefined
        }
    },
    components: {
        StandardInput,
        ccPagination: Paginacao,
        StandardInputListFilteredAuto
    },
    methods: {
        isActiveContainer(tab) {
            if (this.isActiveTab(tab)) return 'page-section-select-size-active'
        },
        isActiveIcon(tab) {
            if (this.isActiveTab(tab)) return 'active-icon'
        },
        isActiveTab(tab) {
            if (tab == this.tab) return true
            return false
        },
        updatePage(page){
            this.isLoading = true
            const params = {page, cli_id: this.clientId, model: 'store'}
            this.svc.getLog(params).then(resp => {
                this.logs = resp.data.data
                this.total_items = resp.data.total
                this.isLoading = false
            })
        },
        setCompany(value) {
            this.clientId = value.id
            this.updatePage(1)
        },
        changeSelect(value) {
        if (!value) return
        this.clientName = value
        if (!this.loading) {
          this.loading = true
          setTimeout(() => {
            this.requestClients()
          },1500)
        }
      },
      requestClients(){
        const config = {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
          params: {
            name: this.clientName,
          },
        };
        axios
          .get(`${CONSTANTS.API_V3_URL}/admin/clients?page=1&sort=l.loj_razao`, config)
          .then(data => {
            this.loading = false
            this.clientsList = []
            data.data.data.forEach(element => {
                this.clientsList.push({
                  text: element.name,
                  value: element
                });
            });
            this.$forceUpdate()
          });
        },
    },
    mounted() {
        this.svc.getLog(1).then(resp => {
            this.logs = resp.data.data
            this.total_items = resp.data.total
            this.isLoading = false
        })
    },
}
</script>

<style lang="scss" scoped>
.page-route-select {
    display: flex;
    align-items: center;
}

.page-route-select-text {
    font-weight: 400;
    font-size: 1.36em;
    line-height: 17px;
    color: #ABABAB;
    margin-right: 20px;
    cursor: pointer;
}
.log-table-header{
    background: #FFFEFC;
    border: 0.5px solid #E5E5E5;
    font-weight: 400;
    color: #605F5F;
}

.page-route-select-icon {
    color: var(--primary-color);
    margin-right: 20px;
}

.page-title {
    margin-top: 45px;
    font-weight: 700;
    font-size: 2.72em;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #605F5F;
}

.tab-selector-text {
    font-weight: 400;
    font-size: 1.5em;
    letter-spacing: 0.15px;
    color: #898989;
}

.page-section-select-size {
    background-color: white;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: 7.5vh;
    min-width: 12vw;
    padding: 0 1vw;
    display: flex;
    align-items: center;
    border: none;
}

.page-section-select-size:hover {
    background-color: #fdf0e9;
}

.page-section-select-size-active {
    cursor: default;
    border-radius: 8px 8px 8px 8px;
    background-color: #fdf0e9;
}

.page-section-select-text {
    font-weight: 600;
    font-size: 1.1em;
    color: #202020;
    margin-left: 1vw;
}

.page-section-select-size:hover>.page-section-select-text {
    color: var(--primary-color);
}

.active-icon {
    color: var(--primary-color) !important;
}

.desactive-icon {
    color: var(--primary-color);
}
.log-table-row{
    font-weight: 300;
    font-size: 1.0em;
    color: #605F5F;
}
.page-table-line-special{background: #F7F7F7;}
.base-width{width: 15%;}
</style>